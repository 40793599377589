import React, { useState, useEffect } from "react";
import Zoom from "react-reveal/Zoom"; // Importing Zoom effect
import { Link, useLocation } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import LazyLoad from 'react-lazyload';


function Product({ formData, setFormData, page, setPage, step5, plans }) {
  const [selected, setSelected] = useState({
    cards: [
      {
        id: step5.acf.step_5[0].product_id,
        Month: step5.acf.step_5[0].product_name,
        isChecked: false,
        img: step5.acf.step_5[0].product_image,
        Price: step5.acf.step_5[0].product_price,
        bestValue: step5.acf.step_5[0].product_label,
        product_extra_info: step5.acf.step_5[0].product_extra_info,
      },
      {
        id: step5.acf.step_5[1].product_id,
        Month: step5.acf.step_5[1].product_name,
        isChecked: false,
        img: step5.acf.step_5[1].product_image,
        Price: step5.acf.step_5[1].product_price,
        bestValue: step5.acf.step_5[1].product_label,
        product_extra_info: step5.acf.step_5[1].product_extra_info,
      },
      {
        id: step5.acf.step_5[2].product_id,
        Month: step5.acf.step_5[2].product_name,
        isChecked: false,
        img: step5.acf.step_5[2].product_image,
        Price: step5.acf.step_5[2].product_price,
        bestValue: step5.acf.step_5[2].product_label,
        product_extra_info: step5.acf.step_5[2].product_extra_info,
      },
      {
        id: step5.acf.step_5[3].product_id,
        Month: step5.acf.step_5[3].product_name,
        isChecked: false,
        img: step5.acf.step_5[3].product_image,
        Price: step5.acf.step_5[3].product_price,
        bestValue: step5.acf.step_5[3].product_label,
        product_extra_info: step5.acf.step_5[3].product_extra_info,
      },
    ],
  });
  const fetchData = async () => {
    try {
      const response = await fetch('https://myirelandbox.blackbird.marketing/api/klaviyoHandler', {
        method: 'POST', // Set the request method to POST
        headers: {
          'Content-Type': 'application/json', // Set the content type for JSON data
        },
        body: JSON.stringify(formData), // Replace with your actual data
      });

      const result = await response.json();
      // setData(result);
      console.log(result)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const goToCart = (productId) => {
    // window.open(
    //   `https://checkout.myirelandbox.com/checkout/buy/${productId}?customer_email=${formData.email}`,
    //   "_self"
    // );
    fetchData()
    window.open(
      `https://checkout.myirelandbox.com/checkout/buy/${productId}`,
      "_self"
    );
 

  };
  const checked = (id) => {
    const newCards = selected.cards.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          isChecked: true,
        };
      } else {
        return {
          ...item,
          isChecked: false,
        };
      }
    });
    setSelected({
      cards: newCards,
    });

    setFormData({
      ...formData,
      product: newCards.filter((item) => item.isChecked)[0],
    });
  };
  var counter = 0;
  for (var i = 0; i < selected.cards.length; i++) {
    if (selected.cards[i].isChecked === false) {
      counter++;
    }
  }
  const [to, setTo] = useState("");
  const location = useLocation();

  useEffect(() => {
    setTo(
      location.pathname.indexOf("cw") !== -1
        ? "/cw/form"
        : location.pathname.indexOf("gift/") !== -1
        ? "/gift/form"
        : "/get-started"
    );

  
  }, [location]);

  return (
    <div style={{ backgroundColor: "#FFFCF8" }}>
      <h1 className="gifting-plans-link">
        Are you interested in MyIrelandBox for yourself? <br></br>
        <Link to={to}>Please follow link here!</Link>
      </h1>
      <Zoom delay={100} duration={200}>
        <div className="products">
          <div className="shop-product-border">
            <div className="shop-product-label">
              <p id="shop-product-label-paragraph">Try MyIrelandBox</p>
            </div>

            <div
              className="box-discount"
              onClick={() => {
                goToCart(plans.acf.shopGift.productid);
              }}
            >
              <div
                className="box box-shop"
                onClick={() => {
                  goToCart(plans.acf.shopGift.productid);
                }}
              ><div className="extraDiv">
                 <LazyLoad height={200}>
                <img src={plans.acf.shopGift.planboxBadge} className="ribon" />
                </LazyLoad>
                <div
                  className="box-plan-image"
                  style={{
                    backgroundImage: `url(${plans.acf.shopGift.planboximg})`,
                  }}
                >
                  {/* <img
                    src={`${plans.acf.shop.shopboxpng}`}
                    className="boxPng"
                  /> */}
                </div>
                <div className="cardText">
                  <div
                    className="sub-type"
                    dangerouslySetInnerHTML={{ __html: plans.acf.shopGift.month }}
                  ></div>
                  <p
                    className="price-plans"
                    dangerouslySetInnerHTML={{ __html: plans.acf.shopGift.price }}
                  ></p>
           
                  {window.location.href.indexOf("cw") !== -1 ||
                  window.location.href.indexOf("gift") !== -1 ? (
                    <div
                      className="extra-plans"
                      dangerouslySetInnerHTML={{
                        __html: plans.acf.shopGift.offertext,
                      }}
                    ></div>
                  ) : (
                    <div
                      className="extra-plans"
                      dangerouslySetInnerHTML={{
                        __html: plans.acf.shopGift.customtext,
                      }}
                    ></div>
                  )}
                  <button
                    className="plans-sub-btn"
                    formTarget="dummyFrame"
                    onClick={() => {
                      goToCart(plans.acf.shopGift.productid);
                    }}
                  >
                    <p
                      dangerouslySetInnerHTML={{
                        __html: plans.acf.shopGift.subscribebtn,
                      }}
                    ></p>
                  </button>
                </div>
              </div>
                  
                {/* <div className="box-notice hide-on-mobile " dangerouslySetInnerHTML={{__html: "<p>Subscription Details</p>",}} data-tip data-for="aMonthySubscription" /> */}

                {/* <ReactTooltip id="aMonthySubscription" place="bottom" backgroundColor="#66AA47" effect="solid"> */}
                  <div className="box-notice" dangerouslySetInnerHTML={{__html:"A  one month prepay gift subscription",}}/>
                {/* </ReactTooltip> */}
              </div>
              <div className="plans-discount-label">
                <p>{plans.acf.shopGift.discount}</p>
              </div>
            </div>
          </div>
          <div className="plans">
            <div className="plans-label">
              <p id="plans-label-gifting-form">Discover Ireland Every Month</p>
            </div>
            {plans.acf.planfirstGift.planboxes.map((e, index) =>

              index !== 0 ? (
                
                <div
                  className="box-discount"
                  onClick={() => {
                    (e.offferproductid !== "" &&
                      window.location.href.indexOf("cw") !== -1) ||
                    window.location.href.indexOf("gift/form") !== -1
                      ? goToCart(e.offferproductid)
                      : goToCart(e.productid);
                  }}
                >
                  <div
                    className="box"
                    onClick={() => {
                      (e.offferproductid !== "" &&
                        window.location.href.indexOf("cw") !== -1) ||
                      window.location.href.indexOf("gift/form") !== -1
                        ? goToCart(e.offferproductid)
                        : goToCart(e.productid);
                    }}
                  >
                    <div className="extraDiv">

                    <LazyLoad height={200}>
                    <img src={e.planboxBadge} className="ribon" />
                    </LazyLoad>
                    <div
                      className="box-plan-image"
                      style={{
                        backgroundImage: `url(${e.planboximg})`,
                      }}
                    >
                      {/* <img src={`${e.boxpng}`} className="boxPng" /> */}
                    </div>
                    <div className="cardText">
                      <div
                        className="sub-type"
                        dangerouslySetInnerHTML={{ __html: e.month }}
                      ></div>
                      <p
                        className="price-plans"
                        dangerouslySetInnerHTML={{ __html: e.price }}
                      ></p>
                 
                 {index === 1 ?  <div
    className="extra-plans"
      dangerouslySetInnerHTML={{ __html: e.offertext }}
    ></div> :  <p className="empty"></p>
                        }
                           {index === 2 ? <div   className="extra-plans extra-pl2"><p></p></div> :  <p className="empty"></p>
                        }
                        {index === 3 ? <div   className="extra-plans extra-pl2"><p></p></div> :  <p className="empty"></p>
                        }
                      <button
                        className="plans-sub-btn"
                        formTarget="dummyFrame"
                        onClick={() => {
                          (e.offferproductid !== "" &&
                            window.location.href.indexOf("cw") !== -1) ||
                          window.location.href.indexOf("gift/form") !== -1
                            ? goToCart(e.offferproductid)
                            : goToCart(e.productid);
                        }}
                      >
                        {index === 1 ? <p>Gift It</p> : 
                        
                         <p
                          dangerouslySetInnerHTML={{ __html: e.subscribebtn }}
                        ></p>
                         }
                        
                      </button>
                    </div>
                    </div>
                    <iframe
                      name="dummyFrame"
                      style={{ display: "none", width: "0", height: "0" }}
                    ></iframe>

                    {/* <div
                  className="box-notice show-on-mobile"
                  dangerouslySetInnerHTML={{ __html: e.tooltip }}
                ></div> */}
                    {/* <div
                      className="box-notice hide-on-mobile"
                      dangerouslySetInnerHTML={{ __html: e.notice }}
                      data-tip
                      data-for={`happyFace${index}`}
                    /> */}

                    {/* <ReactTooltip
                      id={`happyFace${index}`}
                      place="bottom"
                      // type="dark"
                      backgroundColor="#66AA47"
                      effect="solid"
                      // textColor="white"
                    > */}
                      <div
                        className="box-notice"
                        dangerouslySetInnerHTML={{ __html: e.tooltip }}
                      />
                    {/* </ReactTooltip> */}
                  </div>
                  <div className="plans-discount-label">
                    <p>{e.discount}</p>
                  </div>
                </div>
              ) : null
            )}
          </div>
        </div>
      </Zoom>
      <p className="satisfied">{plans.acf.planfirst.notice}</p>
      <Zoom delay={100} duration={200}>
        <div className="commingNext">
          <p id="border-paragraph">What's coming next?</p>
          <p id="nextEvent"> {step5.acf.whats_coming_next_title}</p>
          <p id="nextEvent-description">{step5.acf.whats_coming_next_text}</p>
        </div>
      </Zoom>
    </div>
  );
}

export default Product;
