import React, { useState } from "react";
import "../../Styles/Homepage/firstbanner.css";
import { Link } from "react-router-dom";

import LazyLoad from 'react-lazyload';


function ChristmasBanner({ posts }) {
 

  return (
    <>
      {/*FIRST SECTION DESKTOP*/}
      <div className="first-section"
          style={
            {
                  backgroundImage: `url(${posts.acf.first_section.banner})`,
          }}
      >
        <div className="section-container">
          <div className="first-section-content">
          <LazyLoad height={200}>
          <img className="banner-mobile" src={posts.acf.first_section.banner_mobile} alt="bannermobile"></img>
            </LazyLoad>
            <h1
              className="bannerTitle"
              dangerouslySetInnerHTML={{
                __html: posts.acf.first_section.main_title,
              }}
            ></h1>
            <p
              className="bannerParagraph"
              dangerouslySetInnerHTML={{
                __html: posts.acf.first_section.firstbanner_text2,
              }}
            ></p>
            <div className="bannerBtns christmasBtn">
              <Link
                to="/gifting-form"
                className="button1 "
              >
             Gift a MyIrelandBox
              </Link>
              {/* <Link
                to={
                  window.location.href.indexOf("cw") !== -1
                    ? "/cw/gifting-form"
                    : "/gifting-form"
                }
                className="button2 "
              >
                Gift E-card for Christmas Eve
              </Link> */}
            </div>
           
            <p className="notice">
            {posts.acf.first_section.notice_mobile}
            </p>
            <Link   to={
                  window.location.href.indexOf("cw") !== -1
                    ? "/cw/gifting-form"
                    : "/gifting-form"
                } className="dream">
        <p>What Ships Next?</p>
        <img src= {posts.acf.first_section.shipsnext} alt="#"/>
       </Link>
        </div>
          </div>
        
       
      </div>

    </>
  );
}

export default ChristmasBanner;
