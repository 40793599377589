import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Off from "../Shared/Off";
import FirstBanner from "../Shared/Pages/Homepage/FirstBanner";
import SecondBanner from "../Shared/Pages/Homepage/SecondBanner";
import ThirdBanner from "../Shared/Pages/Homepage/ThirdBanner";
import FourthBanner from "../Shared/Pages/Homepage/FourthBanner";
import SixthBanner from "../Shared/Pages/Homepage/SixthBanner";
import SeventhBanner from "../Shared/Pages/Homepage/SeventhBanner";
import Footer from "../Footer";
import TrustBoxSlider from "../Shared/Form/TrustBoxSlider";
import FacebookGroup from "../Shared/Pages/Homepage/FacebookGroup";
import star from "../../assets/Homepage/star.png"
import quote from "../../assets/Homepage/quote.png"
import LazyLoad from 'react-lazyload';
// import Craft from "../Shared/Pages/Homepage/craft";
import HomepageSlider from "../Shared/Pages/Homepage/homepageSlider";
import Experience from "../Shared/Pages/Homepage/experience";
import Circle from "../Shared/Pages/Homepage/circle";
import Member from "../Shared/Pages/Homepage/member";
import Craft from "../Shared/Pages/Homepage/craft";
import Personalized from "../Shared/Pages/Homepage/Personalized";
import FlipBook from "./Flipper";
import StPatricksDayPromo from "../Shared/Pages/Homepage/ThreeMonths";
function Homepage({ posts, setFounder, boxes, newHp }) {
  const [popup, setPopup] = useState({
    show: false,
    counter: 0,
    close: true,
  });
  
  var display = "";
  if (popup.show === false && popup.counter == 0) {
    display = "none";
  } else if (
    popup.show === false &&
    popup.counter === 1 &&
    popup.close === true
  ) {
    display = "none";
  } else {
    display = "flex";
  }

  return (
    <div>
      <Navbar posts={posts} />

      <FirstBanner posts={posts} />
      {/* <StPatricksDayPromo/> */}
      <TrustBoxSlider />
      <FlipBook/>
      <LazyLoad height={200}>
      <SecondBanner posts={posts} />
      </LazyLoad>
    <Craft   posts={posts}/>
    {/* <Craft   posts={posts}/> */}
    
    
      <Member  posts={posts}/>
      {/* <Personalized/> */}
   
    
      
      <LazyLoad height={200}>
      {/* <ThirdBanner posts={posts} boxes={boxes} /> */}
      </LazyLoad>    <LazyLoad height={200}>
      {/* <FourthBanner posts={posts} /> */}
      </LazyLoad>    <LazyLoad height={200}>
      {/* <SixthBanner posts={posts} setFounder={setFounder} /> */}
<Experience/>
      <div className="verified">
      <img src={quote} alt="star" />
        <p>I am so happy with my decision to subscribe to MyIreland Box!
         It was absolutely perfect! My heart is in west Ireland and my first
         box highlighted all the areas I want to see and visit. The box was
         full of lovely gifts, an amazing little book about Ireland, and so
         worth every penny!</p>
     <img src={star} alt="star" />
     <p className="verified-customer">– Verified Customer</p>
      </div>
      <HomepageSlider/>
 
      <Circle/>
      
     
      </LazyLoad>    <LazyLoad height={200}>
      <FacebookGroup posts={posts} />
      </LazyLoad>    <LazyLoad height={200}>
      <SeventhBanner posts={posts} />
      </LazyLoad>
      <Footer />
    </div>
  );
}

export default Homepage;
