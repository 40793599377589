import React from "react";
import as from "../../../../assets/ar.png"
import "../../Styles/Homepage/threeMonths.css";

const StPatricksDayPromo = () => {
    const goToCart = (productId) => {
    
        window.open(
          `https://checkout.myirelandbox.com/checkout/buy/${productId}`,
          "_self"
        );
      };
  return (
    <div className="promo-container">
          <div className="promoCont">
          <div className="promo-header">Exciting Update</div>
      <h1 className="promo-title">
      St. Patrick’s Day Celebrations CONTINUE!
      </h1>
      <p>To make the experience more pleasant, we're going to be sending to your chosen gift recipient a personalized e-card on your behalf letting them know that three wonderful curations are coming their way!</p>
      <button className="promo-button"  onClick={() => {
         window.location.href.includes("cw") ?
                goToCart(325122) :  goToCart(325122)
                }}>CLICK HERE TO GET STARTED</button>
                <img src={as} alt="as" className="asImage"/>
      {/* <p className="promo-text">
      Sign up for 3 months of MyIrelandBox membership and receive:
      </p>
      <ul className="promo-list">
        <li>🍀 Start your journey with April’s cozy Irish Farmhouse Box</li>
        <li>
          🍀 In May, we explore history & emigration with the 'Isle of Home' MyIrelandBox
        </li>
        <li>🍀  Lastly, in June it's all about sunshine and strolls in an Irish city</li>
      </ul>
      <p className="promo-bonus">
        As a Bonus Your first MyIrelandBox will also include a{" "}
        <span className="highlight">FREE $70 Claddagh Necklace</span>
      </p> */}
          </div>
    </div>
  );
};

export default StPatricksDayPromo;
