import React, { useEffect, useState } from "react";
import Zoom from "react-reveal/Zoom"; 
import { Link, useLocation } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import LazyLoad from 'react-lazyload';
import sham from "../../../assets/gra.png"

function Product({ formData, step5, plans }) {
  // console.log(formData.email);
  const fetchData = async () => {
    try {
      const response = await fetch('https://myirelandbox.blackbird.marketing/api/klaviyoHandler', {
        method: 'POST', // Set the request method to POST
        headers: {
          'Content-Type': 'application/json', // Set the content type for JSON data
        },
        body: JSON.stringify(formData), // Replace with your actual data
      });

      const result = await response.json();
      // setData(result);
      console.log(result)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const goToCart = (productId) => {
    fetchData()
    window.open(
      `https://checkout.myirelandbox.com/checkout/buy/${productId}`,
      "_self"
    ); 
  };

  const [to, setTo] = useState("");
  const location = useLocation();
  useEffect(() => {
    setTo(
      location.pathname.indexOf("cw") !== -1
        ? "/cw/gifting-form"
        : location.pathname.indexOf("gift/") !== -1
        ? "/gift/gifting-form"
        : "/gifting-form"
    );
   
  }, [location]);
  return (
    <div style={{ backgroundColor: "#FFFCF8" }}>
      <h1 className="gifting-plans-link">
        Are you interested in gifting a MyIrelandBox? <br></br>
        <Link to={to}>Please follow link here!</Link>
      </h1>
      <Zoom delay={100} duration={200}>
        <div className="products">

          <div className="shop-product-border">
            <div className="shop-product-label">
              <p id="shop-product-label-form">Try MyIrelandBox</p>
            </div>

            <div className="box-discount" onClick={() => {goToCart(plans.acf.shopStarted.productid);}}>

              <div className="box box-shop" onClick={() => {goToCart(plans.acf.shopStarted.productid);}} >

               <div className="extraDiv">
                  {/* <img src={plans.acf.shop.planboxBadge} className="ribon" /> */}
                  <div className="fullWidth ">
<div className="box-plan-image" style={{backgroundImage: `url(${plans.acf.shopStarted.planboximg})`,}}>
<LazyLoad height={200}>
  <img src={`${plans.acf.shopStarted.shopboxpng}`} className="boxPng" alt="boxPng"/>
  </LazyLoad>
</div>
</div>
<div className="cardText">
  <div className="sub-type" dangerouslySetInnerHTML={{ __html: plans.acf.shopStarted.month }} ></div>
  <p className="price-plans" dangerouslySetInnerHTML={{ __html: plans.acf.shopStarted.price }} ></p>
  <div className="extra-plans" dangerouslySetInnerHTML={{__html: plans.acf.shopStarted.customtext,}}></div>
  <button className="plans-sub-btn" formTarget="dummyFrame" onClick={() => { goToCart(plans.acf.shopStarted.productid);}}>
    <p dangerouslySetInnerHTML={{__html: plans.acf.shopStarted.subscribebtn, }}></p>
  </button>
</div>
</div>
                {/* <div className="box-notice hide-on-mobile " dangerouslySetInnerHTML={{__html: "<p>Subscription Details</p>",}} data-tip data-for="aMonthySubscription" /> */}

                {/* <ReactTooltip id="aMonthySubscription" place="bottom" backgroundColor="#66AA47" effect="solid"> */}
                  <div className="box-notice" dangerouslySetInnerHTML={{__html:"The monthly subscription is billed every month and can be cancelled anytime.",}}/>
                {/* </ReactTooltip> */}
              </div>

              <div className="plans-discount-label">
                <p>{plans.acf.shopStarted.discount}</p>
              </div>
            </div>

          </div>

          <div className="plans">
            <div className="plans-label">
              <p id="plans-label-id">Discover Ireland Every Month</p>
            </div>
            {plans.acf.planFirstStarted.planboxes.map((e, index) =>
              index !== 0 ? (
                <div className="box-discount"
                  onClick={() => {
                    (e.offerproductid !== "" &&
                      window.location.href.indexOf("cw") != -1) ||
                    window.location.href.indexOf("gift/") != -1
                      ? goToCart(e.offerproductid)
                      : goToCart(e.productid);
                  }}
                >
                  <div className="box"
                    onClick={() => {
                      (e.offerproductid !== "" &&
                        window.location.href.indexOf("cw") != -1) ||
                      window.location.href.indexOf("gift/") != -1
                        ? goToCart(e.offerproductid)
                        : goToCart(e.productid);
                    }}
                  >
                    <div className="extraDiv">
                    <LazyLoad height={200}>
                    <img src={e.planboxBadge} className="ribon" />
                    </LazyLoad>
                    <div className={`fullWidth ${ 
  index === 1 
    ? (window.location.href.includes("cw") 
      ? "" 
      : "borderNeck") 
    : ""
}`}>
                    <div className="box-plan-image"
                      style={{
                        backgroundImage: `url(${e.planboximg})`,
                      }}
                    >
                        {/* <LazyLoad height={200}>
                      <img src={`${e.boxpng}`} className="boxPng" />
                      </LazyLoad> */}
                    </div>
                    {index === 1 ? 
                  <div className="silverPrice">
                     <p>Included: Hand-made Claddagh Necklace</p>
                    <img src={sham} alt="price"/>
                  </div> :""}
                  </div>
                    <div className="cardText">
                      <div className="sub-type"
                        dangerouslySetInnerHTML={{ __html: e.month }}
                      ></div>
                      <p className="price-plans"
                        dangerouslySetInnerHTML={{ __html: e.price }}
                      ></p>{index === 1 ? <div   className="extra-plans extra-pl2"     dangerouslySetInnerHTML={{ __html: e.offertext	 }}></div> :  <p></p>
                    }
                       {index === 2 ? <div   className="extra-plans extra-pl2"     dangerouslySetInnerHTML={{ __html: e.offertext	 }}></div>:  <p className="empty"></p>
                    }
                    {index === 3 ? <div   className="extra-plans extra-pl2"     dangerouslySetInnerHTML={{ __html: e.offertext	 }}></div> :  <p className="empty"></p>
                    }
                

                      <button className="plans-sub-btn" formTarget="dummyFrame"
                        onClick={() => {
                          goToCart(e.productid);
                        }}
                      >
                          {index === 1 ? <p>Get Offer</p> : 
                        
                        <p
                         dangerouslySetInnerHTML={{ __html: e.subscribebtn }}
                       ></p>
                        }
                      </button>
                      <iframe name="dummyFrame" style={{ display: "none", width: "0", height: "0" }} ></iframe>
                    </div>

                    </div>
                    {/* <div className="box-notice hide-on-mobile" dangerouslySetInnerHTML={{ __html: e.notice }} data-tip data-for={`happyFace${index}`} /> */}

                    {/* <ReactTooltip id={`happyFace${index}`} place="bottom" backgroundColor="#66AA47" effect="solid" > */}
                      <div className="box-notice" dangerouslySetInnerHTML={{ __html: e.tooltip }} />
                    {/* </ReactTooltip> */}
                  </div>
                  <div className="plans-discount-label">
                    <p>{e.discount}</p>
                  </div>
                </div>
              ) : null
            )}
          </div>

          <iframe name="dummyFrame" style={{ display: "none", width: "0", height: "0" }} ></iframe>
        </div>
      </Zoom>
      <p className="satisfied">
        If your aren't 100% satisfied with MyIrelandBox, we guarantee to make it
        right with you. Subscriptions renew automatically unless cancelled or
        paused - which you can do anytime. You are committing to the length of
        your MyIrelandBox plan.
      </p>
      <Zoom delay={100} duration={200}>
        <div className="commingNext">
          <p id="border-paragraph">What's coming next?</p>
          <p id="nextEvent"> {step5.acf.whats_coming_next_title}</p>
          <p id="nextEvent-description">{step5.acf.whats_coming_next_text}</p>
        </div>
      </Zoom>
    </div>
  );
}

export default Product;
