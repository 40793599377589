import React, { useEffect, useState } from "react";
import LazyLoad from "react-lazyload";
import Modal from 'react-modal';
import { Link } from "react-router-dom";

import all from "../../../../assets/pr.png";
import allMob from "../../../../assets/gl.png";

const Craft = ({ posts }) => {

    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
      setIsOpen(true);
    }
  
    function afterOpenModal() {
      // references are now sync'd and can be accessed.
      // document.getElementById("body").style.opacity = "0.1";
      document.getElementById("modal-content2").classList.add("modal-fade-in");
    }
  
    function closeModal() {
      setIsOpen(false);
    }
  
  
  
  
  
    return  (
        <div className="third-section">

        <div className="nextship">
          <div className="ship-left">
            <p className="txt-head">The Golden Shamrock Prize</p>
            <h1 className="handbag-title" style={{fontFamily:"The new Elegance"}}>“Waves of Home” Pendant



</h1>
            <div className="ship-right mobile-ship">
          <LazyLoad height={200}>
            <img src={all} className="txt-img desPhoto" />
            <img src={allMob} className="txt-img mobilePhoto" />

            </LazyLoad>
          </div>
            <p className="txt-paragraph">This special pendant honours the journey of Irish emigrants to the USA and Canada and further afield—those who crossed the sea with hope in their hearts and Ireland in their souls.
            <br/>
            Handcrafted in Dingle, Ireland by beloved (and now retired) goldsmith Niamh Utch, this rare piece features a flowing 18ct yellow gold wave on textured sterling silver. A glistening aquamarine evokes the sea, while a delicate gold sun shines with warmth and light.

<br/>
Strung on a sterling silver trace chain (45cm / 18”), “Waves of Home” is a radiant tribute to Irish heritage and connection across the ocean.
<br/>
Valued at $450 – this Golden Shamrock Prize will go to one lucky member.
All active members on 1st May will be entered into the draw.<br/>
Go néirí leat – best of luck!






            </p>
             <div className="handbag-display">
             <Link to={window.location.href.includes("christmas-ecard") ? "/gifting-form" :"/get-started"}  >{window.location.href.includes("christmas-ecard") ? "Gift to Enter Draw" :"Subscribe to Enter Draw"} </Link>
             <p onClick={openModal} className="hoverable">See Rules</p>
             </div>
          </div>
          <div className="ship-right desktop-ship">
          <LazyLoad height={200}>
            <img src={all} className="txt-img" />
            </LazyLoad>
          </div>

          <div className="thebade" onClick={openModal} >
        <LazyLoad height={200}>
             <img src={posts.acf.modalBadge} id="badge-btn-image" alt="badgeimage"></img>
        </LazyLoad>
      </div>
        </div>

            
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        id="modal-content2"
        contentLabel="Example Modal"
      >
      <div className="draw-offer-homepage" >
        <div onClick={closeModal} id="closemodal-badge2">X</div>
            <LazyLoad height={200}>
        <img id="badge3" src={posts.acf.modalBadge} alt="badgeimage
        "></img>
        </LazyLoad>
          <p   dangerouslySetInnerHTML={{
            __html: posts.acf.modalcontent2,
          }}></p>
          <Link to="/get-started">
        <button className="modalbtn " onClick={closeModal}>
          Continue to Enter
        </button>
        </Link>
        </div>
      </Modal>

     </div>
    )
}

export default Craft;