import React, { useEffect, useState } from "react";

function CountDown({ posts, style }) {
  // Function to calculate the time left until the end of the current month
  const calculateTimeLeft = () => {
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth();

    // Set the target date to the last day of the current month
    const targetDate = new Date(currentYear, currentMonth + 1, 0); // Last day of the current month

    // If today is past the last day of the current month, set the countdown for next month
    if (now > targetDate) {
      targetDate.setMonth(currentMonth + 1);
      targetDate.setDate(0); // Get the last day of the next month
    }

    const difference = targetDate - now;

    if (difference > 0) {
      return {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const countdownAllow = posts.acf.first_section.countdownallow;

  return (
    <>
      {(timeLeft.days <= 8 || countdownAllow) && (
        <div className={style + " ensureChristmas-navbar"} style={{ display: "flex" }}>
          <p className="ensure">{posts.acf.first_section.countdown_paragraph}</p>
          <div className="counter">
            <div className="days">
              <h1>{timeLeft.days}</h1>
              <p>DAYS</p>
            </div>
            <div className="dots">:</div>
            <div className="hours">
              <h1>{timeLeft.hours}</h1>
              <p>HOURS</p>
            </div>
            <div className="dots">:</div>
            <div className="minutes">
              <h1>{timeLeft.minutes}</h1>
              <p>MINUTES</p>
            </div>
            <div className="dots">:</div>
            <div className="sec">
              <h1>{timeLeft.seconds}</h1>
              <p>SECONDS</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CountDown;
